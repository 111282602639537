body {
  background-color: #252525;
  color: #fff;
  font-size: 25px;
  font-family: Mitr, sans-serif;
}

h1 {
  text-align: right;
  font-size: 35px;
}

a {
  color: #fff;
  text-decoration: none;
}

a:hover {
  color: #fff;
  text-decoration: underline;
}

img {
  border: 5px solid #fff;
}

.container {
  max-width: 1090px;
}
